export enum Action {
  API = 'api/',

  Token = 'jwt',
  RefreshToken = 'jwt/refresh',
  ClearToken = 'jwt/clear',

  Register = 'register',
  ValidateInviteToken = 'register/validate_invite_token',

  InviteAdmin = 'register/invite_admin',
  RegisterAdmin = 'register/admin',

  InviteOwner = 'register/invite_owner',
  RegisterOwner = 'register/owner',

  InviteManager = 'register/invite_manager',
  RegisterManager = 'register/manager',

  InviteConsultant = 'register/invite_consultant',
  RegisterConsultant = 'register/consultant',

  InviteMarketer = 'register/invite_marketer',
  RegisterMarketer = 'register/marketer',

  UsernameExists = 'user_exists',

  Activation = 'user/activation',
  ResendActivation = 'user/resend_activation',
  ResetPassword = 'user/reset_password',
  ResetPasswordConfirm = 'user/reset_password_confirm',
  SetPassword = 'user/set_password',

  AdminUsers = 'admin_user',
  AdminUserDetail = 'admin_user/<userId>',
  DeleteAdminUser = 'admin_user/<userId>/delete',
  ActivateAdminUser = 'admin_user/<userId>/activate_user',
  DeactivateAdminUser = 'admin_user/<userId>/deactivate_user',
  SimpleUserList = 'admin_user/simple_user_list',
  Roles = 'roles',

  FruitSizing = 'fruit_sizing',
  FruitSizeDistributionGraph = 'fruit_sizing/fruit_size_graph',
  FruitSizeDistributionGraphOld = 'fruit_sizing/fruit_size_graph_old',
  RateOfFruitSizeIncreaseGraph = 'fruit_sizing/rate_of_fruit_size_increase_graph',

  Diary = 'diary',

  SimpleBlocks = 'blocks/simple',
  Blocks = 'blocks',
  MapFeatureBlock = 'blocks/{id}/map_feature_detail',
  BlocksWithFruitCounts = 'blocks/with_fruit_count',
  BlocksWithOnlyFruitCounts = 'blocks/with_only_fruit_count',
  BlocksWithAvgDM = 'blocks/with_avg_dm',
  BlocksWithFloweringCount = 'blocks/with_flowering_count',
  BlocksWithFruitSize = 'blocks/with_fruit_size',
  GeomBlocks = 'blocks/with_geom',
  BlockSummary = 'blocks/summary',
  BlockFeatureInfo = 'blocks/block_feature_info/<farmId>/<latitude>/<longitude>',
  AssignCrop = 'blocks/assign_crop',
  AssignCorrectionFactor = 'blocks/assign_correction_factor',
  ImportBlocks = 'import_blocks',
  ExportBlocks = 'blocks/export_polygon',
  ExportBlock = 'blocks/{block_id}/export_polygon',
  ExportFarmBlocks = 'blocks/export_farm_polygon/{farm_id}',

  Farms = 'farms',
  MyDefaultFarm = 'farms/default_farm',
  FarmSensor = 'farm_sensors',

  Flowering = 'flowering',
  FloweringDateList = 'flowering/date_list',
  CanUploadFloweringImage = 'flowering/can_upload_image',
  canUploadMultipleFloweringImage = 'flowering/can_upload_image_list',
  UploadFloweringImage = 'flowering/upload_image',
  FloweringImage = 'flowering_images',
  FloweringBatchUpload = 'flowering_batch_upload',
  DownloadFloweringData = 'flowering_batch_upload/download',
  FloweringBatchUploadProcessingStatus = 'flowering_batch_upload/<id>/processing_status',
  RawFloweringBatchUpload = 'raw_flowering_batch_upload',
  DownloadRawFloweringData = 'raw_flowering_batch_upload/download',
  FindBatchDeleteRawFlowering = 'raw_flowering_batch_upload/find_for_deletion',
  BatchDeleteRawFlowering = 'raw_flowering_batch_upload/delete_by_farm',
  RawFloweringBatchUploadProcessingStatus = 'raw_flowering_batch_upload/<id>/processing_status',
  FindBatchDeleteFlowering = 'flowering_batch_upload/find_for_deletion',
  BatchDeleteFlowering = 'flowering_batch_upload/delete_by_farm',
  FlowerDistributionGraph = 'flowering/flower_distribution_graph',
  FlowerLinearGraph = 'flowering/flower_linear_graph',

  FruitSize = 'fruit_sizing',
  FruitSizeDateList = 'fruit_sizing/date_list',
  CanUploadFruitSizeImage = 'fruit_sizing/can_upload_image',
  canUploadMultipleFruitSizeImage = 'fruit_sizing/can_upload_image_list',
  UploadFruitSizeImage = 'fruit_sizing/upload_image',
  FruitSizeImage = 'fruit_sizing_images',
  FruitSizeBatchUpload = 'fruit_sizing_batch_upload',
  DownloadFruitSizeData = 'fruit_sizing_batch_upload/download',
  FruitSizeBatchUploadProcessingStatus = 'fruit_sizing_batch_upload/<id>/processing_status',
  FindBatchDeleteFruitSize = 'fruit_sizing_batch_upload/find_for_deletion',
  BatchDeleteFruitSize = 'fruit_sizing_batch_upload/delete_by_farm',

  TreeBatchUpload = 'tree_batch_upload',
  TreeBatchUploadProcessingStatus = 'tree_batch_upload/<id>/processing_status',

  PackHouseBatchUpload = 'pack_house_batch_upload',

  FruitCounts = 'fruit_counts',
  FruitCountDateList = 'fruit_counts/date_list',
  CanUploadFruitCountImage = 'fruit_counts/can_upload_image',
  canUploadMultipleFruitCountImage = 'fruit_counts/can_upload_image_list',
  UploadFruitCountImage = 'fruit_counts/upload_image',
  FruitDistributionGraph = 'fruit_counts/fruit_distribution_graph',
  FruitCountImage = 'fruit_count_images',
  PackHouseGraph = 'fruit_counts/pack_house_graph',

  FruitCountBatchUpload = 'fruit_count_batch_upload',
  DownloadFruitCountData = 'fruit_count_batch_upload/download',
  FruitCountBatchUploadProcessingStatus = 'fruit_count_batch_upload/<id>/processing_status',
  FindBatchDeleteFruitCount = 'fruit_count_batch_upload/find_for_deletion',
  BatchDeleteFruitCount = 'fruit_count_batch_upload/delete_by_farm',

  DryMatterBatchUpload = 'dry_matter_batch_upload',
  DownloadDryMatterData = 'dry_matter_batch_upload/download',
  DryMatterDateList = 'dry_matters/date_list',
  DryMatterDistributionGraph = 'dry_matters/dry_matter_distribution_graph',
  DryMatterPercentIncreaseGraph = 'dry_matters/dry_matter_percent_increase_graph',
  DryMatterBatchUploadProcessingStatus = 'dry_matter_batch_upload/<id>/processing_status',
  FindBatchDeleteDryMatter = 'dry_matter_batch_upload/find_for_deletion',
  BatchDeleteDryMatter = 'dry_matter_batch_upload/delete_by_farm',

  FieldBinBatchUpload = 'field_bin_batch_upload',
  FieldBinBatchUploadProcessingStatus = 'field_bin_batch_upload/<id>/processing_status',
  FindBatchDeleteFieldBin = 'field_bin_batch_upload/find_for_deletion',
  BatchDeleteFieldBin = 'field_bin_batch_upload/delete_by_farm',
  DownloadFieldBinData = 'field_bin_batch_upload/download',
  CanUploadFieldBinImage = 'field_bins/can_upload_image',
  canUploadMultipleFieldBinImage = 'field_bins/can_upload_image_list',

  PercentDryMatterIncrease = 'percentage_dry_matter_increases',
  PercentDMUpdateSeason = 'percentage_dry_matter_increases/update_season',
  CalculatePercentDMUpdateSeason = 'percentage_dry_matter_increases/calculate_estimate_dm_percent_increase/<blockId>/<seasonId>',

  FlowerEvent = 'flower_events',
  UpdateMultipleFlowerEvent = 'flower_events/multiple_update',

  RawFlowerings = 'raw_flowerings',
  RawTransposedFlowerings = 'raw_flowerings/transposed',
  RawFloweringHarvestDate = 'raw_flowerings/harvest_date',

  YieldByWeekTransposed = 'yield_by_week/transposed',
  YieldByWeekDate = 'yield_by_week/harvest_date',
  FarmYieldByWeekTransposed = 'farm_yield_by_week/transposed',

  FlowerEventHeatUnitGraph = 'fe_heat_units/graph',
  MinMaxTemperatureGraph = 'fe_heat_units/temperature_graph',

  Sensor = 'sensors',
  SensorProfileGraphBySensorId = 'sensors/profile/<sensorId>',
  SensorDateDataDownload = 'sensor-date-data/download/<sensorId>',
  SensorLocation = 'sensor_locations',
  SensorProfileGraphBySensorLocationId = 'sensor_locations/<sensorLocationId>/sensor_profile_graph',
  GeomSensorLocations = 'sensor_locations/with_geom',

  Metstations = 'metstations',
  MetStationProfileGraphByMetStationId = 'metstations/<metStationId>/profile',
  MetstationHistoryDownload = 'metstation-histories/download/<stationId>',

  SensorLocationHistory = 'sensor_location_histories',
  CurrentSensorLocationSensor = 'sensor_location_histories/current_sensor',
  AttachSensorLocationSensor = 'sensor_location_histories/attach_sensor',
  DetachSensorLocationSensor = 'sensor_location_histories/detach_sensor',

  Crops = 'crops',
  CropDetail = 'crops/<id>',
  UniqueCrops = 'crops/unique_crop_list',
  CropTypes = 'crop_types',
  CropVarieties = 'crop_varieties',

  CurrentSensor = 'block_sensor_histories/current_sensor',
  AttachSensor = 'block_sensor_histories/attach_sensor',
  DetachSensor = 'block_sensor_histories/detach_sensor',

  ProxyView = 'proxy',

  Layers = 'layers',
  GroupedLayers = 'layers/grouped_layers',
  GeoserverConfig = 'geoserver_config',

  SMTPSettings = 'smtp_settings',
  TestSMTPSettings = 'smtp_settings/test',

  SeasonCalendar = 'season_calendars',
  SeasonCalendarDetail = 'season_calendars/<id>',
  Season = 'seasons',
  SeasonDetail = 'seasons/<id>',
  LatestSeasonDate = 'farms/<id>/latest_season_date',

  SendCategoricalEmail = 'send_categorical_email',
  SendEmail = 'send_email',
  FreeConsultRequest = 'free_consult_requests',

  Invitations = 'invitations',
  CancelInvitation = 'invitations/<id>/cancel',

  Blogs = 'blogs',
  MyBlogs = 'blogs/my_blogs',
  BlogDetail = 'blogs/<id>',
  BlogCategories = 'blog_categories',
  CKEditorUpload = 'ckeditor/upload',

  Forums = 'forums',
  ForumDetail = 'forums/<id>',
  ReplyForum = 'forums/<id>/reply',
  DiscussionSummary = 'forum_discussions/summary',
  Discussions = 'forum_discussions',
  DiscussionDetail = 'forum_discussions/<id>',
  CloseDiscussion = 'forum_discussions/<id>/close',

  WebPushSave = 'webpush/save_information',
  WebPushSubscribe = 'user/subscribe',

  Notifications = 'notifications',
  NotificationsCount = 'notifications/count',
  MarkNotificationAsRead = 'notifications/<id>/mark_as_read',
  MarkNotificationAsUnRead = 'notifications/<id>/mark_as_unread',
  MarkAllNotificationAsRead = 'notifications/mark_all_as_read'
}
