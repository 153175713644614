import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import { EstimatedDMPercentIncrease } from '@/store/models/configuration';
import {
  CanUploadFloweringImage,
  CanUploadFruitCountImage,
  CanUploadFruitSizeImage,
  DeleteFruitCount,
  DeleteRawFlowering,
  FlowerEvent,
  MultipleFlowerEvent,
  RawFloweringData,
  RawFloweringPatchData,
  FruitCount
} from '@/store/models/flowering';

export default new (class FloweringAPI {
  public async batchUploadRawFlowerings(data: FormData) {
    const response = await postAPI(Action.RawFloweringBatchUpload, data);
    return response;
  }
  public async updateRawFloweringProcessingStatus(id: number) {
    const url = Action.RawFloweringBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }
  public async findBatchDeleteRawFlowering(data: DeleteRawFlowering) {
    const response = await postAPI(Action.FindBatchDeleteRawFlowering, data);
    return response;
  }
  public async batchDeleteRawFlowering(data: DeleteRawFlowering) {
    const response = await postAPI(Action.BatchDeleteRawFlowering, data);
    return response;
  }

  public async batchUploadFlowerings(data: FormData) {
    const response = await postAPI(Action.FloweringBatchUpload, data);
    return response;
  }
  public async updateFloweringProcessingStatus(id: number) {
    const url = Action.FloweringBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }

  public async downloadFloweringsData(data: FormData) {
    const response = await postAPI(Action.DownloadFloweringData, data, {
      responseType: 'blob'
    });
    return response;
  }

  public async batchUploadTrees(data: FormData) {
    const response = await postAPI(Action.TreeBatchUpload, data);
    return response;
  }
  public async updateTreeProcessingStatus(id: number) {
    const url = Action.TreeBatchUploadProcessingStatus.replace('<id>', `${id}`);
    const response = await getAPI(url);
    return response;
  }
  public async canUploadFloweringImage(data: CanUploadFloweringImage) {
    const response = await postAPI(Action.CanUploadFloweringImage, data);
    return response;
  }
  public async canUploadMultipleFloweringImage(
    data: CanUploadFloweringImage[]
  ) {
    const response = await postAPI(
      Action.canUploadMultipleFloweringImage,
      data
    );
    return response;
  }
  public async findBatchDeleteFlowering(data: DeleteFruitCount) {
    const response = await postAPI(Action.FindBatchDeleteFlowering, data);
    return response;
  }
  public async batchDeleteFlowering(data: DeleteFruitCount) {
    const response = await postAPI(Action.BatchDeleteFlowering, data);
    return response;
  }

  public async batchUploadFruitSizes(data: FormData) {
    const response = await postAPI(Action.FruitSizeBatchUpload, data);
    return response;
  }
  public async updateFruitSizeProcessingStatus(id: number) {
    const url = Action.FruitSizeBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }

  public async downloadFruitSizeData(data: FormData) {
    const response = await postAPI(Action.DownloadFruitSizeData, data);
    return response;
  }
  public async canUploadFruitSizeImage(data: CanUploadFruitSizeImage) {
    const response = await postAPI(Action.CanUploadFruitSizeImage, data);
    return response;
  }
  public async canUploadMultipleFruitSizeImage(
    data: CanUploadFruitSizeImage[]
  ) {
    const response = await postAPI(
      Action.canUploadMultipleFruitSizeImage,
      data
    );
    return response;
  }

  public async getFruitSizes(query = '') {
    const response = await getAPI(Action.FruitSize, query);
    return response;
  }
  public async getFruitSizeDates(query = '') {
    const response = await getAPI(Action.FruitSizeDateList, query);
    return response;
  }
  public async findBatchDeleteFruitSize(data: DeleteFruitCount) {
    const response = await postAPI(Action.FindBatchDeleteFruitSize, data);
    return response;
  }
  public async batchDeleteFruitSize(data: DeleteFruitCount) {
    const response = await postAPI(Action.BatchDeleteFruitSize, data);
    return response;
  }

  public async getFlowerings(query = '') {
    const response = await getAPI(Action.Flowering, query);
    return response;
  }
  public async getFloweringsDates(query = '') {
    const response = await getAPI(Action.FloweringDateList, query);
    return response;
  }
  public async batchUploadFruitCount(data: FormData) {
    const response = await postAPI(Action.FruitCountBatchUpload, data);
    return response;
  }
  public async updateFruitCountProcessingStatus(id: number) {
    const url = Action.FruitCountBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }
  public async canUploadFruitCountImage(data: CanUploadFruitCountImage) {
    const response = await postAPI(Action.CanUploadFruitCountImage, data);
    return response;
  }
  public async canUploadMultipleFruitCountImage(
    data: CanUploadFruitCountImage[]
  ) {
    const response = await postAPI(
      Action.canUploadMultipleFruitCountImage,
      data
    );
    return response;
  }
  public async downloadFruitCountData(data: FormData) {
    const response = await postAPI(Action.DownloadFruitCountData, data, {
      responseType: 'blob'
    });
    return response;
  }
  public async batchUploadDryMatter(data: FormData) {
    const response = await postAPI(Action.DryMatterBatchUpload, data);
    return response;
  }
  public async updateDryMatterProcessingStatus(id: number) {
    const url = Action.DryMatterBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }
  public async findBatchDeleteDryaMatter(data: DeleteFruitCount) {
    const response = await postAPI(Action.FindBatchDeleteDryMatter, data);
    return response;
  }
  public async batchDeleteDryMatter(data: DeleteFruitCount) {
    const response = await postAPI(Action.BatchDeleteDryMatter, data);
    return response;
  }
  public async downloadDryMatterData(data: FormData) {
    const response = await postAPI(Action.DownloadDryMatterData, data, {
      responseType: 'blob'
    });
    return response;
  }
  public async findBatchDeleteFruitCount(data: DeleteFruitCount) {
    const response = await postAPI(Action.FindBatchDeleteFruitCount, data);
    return response;
  }
  public async batchDeleteFruitCount(data: DeleteFruitCount) {
    const response = await postAPI(Action.BatchDeleteFruitCount, data);
    return response;
  }
  public async getFruitCounts(query = '') {
    const response = await getAPI(Action.FruitCounts, query);
    return response;
  }
  public async createFruitCount(data: FruitCount) {
    const response = await postAPI(Action.FruitCounts, data);
    return response;
  }
  public async getFruitCountDates(query = '') {
    const response = await getAPI(Action.FruitCountDateList, query);
    return response;
  }

  public async getFlowerEventHeatUnitGraph(query = '') {
    const response = await getAPI(Action.FlowerEventHeatUnitGraph, query);
    return response;
  }
  public async getMinMaxTemperatureGraph(query = '') {
    const response = await getAPI(Action.MinMaxTemperatureGraph, query);
    return response;
  }
  public async getAllFlowerEvents(query = '') {
    const response = await getAPI(Action.FlowerEvent, query);
    return response;
  }
  public async getFlowerEventById(id: number) {
    const response = await getAPI(`${Action.FlowerEvent}/${id}`);
    return response;
  }
  public async createFlowerEvent(event: FlowerEvent) {
    const response = await postAPI(Action.FlowerEvent, event);
    return response;
  }
  public async updateFlowerEvent(event: FlowerEvent) {
    const response = await putAPI(`${Action.FlowerEvent}/${event.id}`, event);
    return response;
  }
  public async createMultipleFlowerEvent(event: MultipleFlowerEvent) {
    const response = await postAPI(Action.FlowerEvent, event);
    return response;
  }
  public async updateMultipleFlowerEvent(event: MultipleFlowerEvent) {
    const response = await patchAPI(
      `${Action.UpdateMultipleFlowerEvent}`,
      event
    );
    return response;
  }
  public async deleteFlowerEvent(id: number) {
    const response = await deleteAPI(`${Action.FlowerEvent}/${id}`);
    return response;
  }
  public async getRawTransposedFlowerings(query = '') {
    const response = await getAPI(Action.RawTransposedFlowerings, query);
    return response;
  }
  public async getRawFloweringHarvestDate(query = '') {
    const response = await getAPI(Action.RawFloweringHarvestDate, query);
    return response;
  }
  public async getYieldByWeekTransposed(query = '') {
    const response = await getAPI(Action.YieldByWeekTransposed, query);
    return response;
  }
  public async getFarmYieldByWeekTransposed(query = '') {
    const response = await getAPI(Action.FarmYieldByWeekTransposed, query);
    return response;
  }
  public async createRawFloweringData(data: RawFloweringData) {
    const response = await postAPI(`${Action.RawFlowerings}`, data);
    return response;
  }
  public async updateRawFloweringData(data: RawFloweringPatchData) {
    const response = await patchAPI(`${Action.RawFlowerings}/${data.id}`, data);
    return response;
  }
  public async downloadRawFloweringData(data: FormData) {
    const response = await postAPI(Action.DownloadRawFloweringData, data, {
      responseType: 'blob'
    });
    return response;
  }
  public async getDryMattertDates(query = '') {
    const response = await getAPI(Action.DryMatterDateList, query);
    return response;
  }
  public async calculatePercentDmIncrease(blockId: number, seasonId: number) {
    const url = Action.CalculatePercentDMUpdateSeason.replaceAll(
      '<blockId>',
      `${blockId}`
    ).replaceAll('<seasonId>', `${seasonId}`);
    const response = await getAPI(url);
    return response;
  }
  public async updateSeasonsDmPercentIncrease(
    data: EstimatedDMPercentIncrease
  ) {
    const response = await postAPI(Action.PercentDMUpdateSeason, data);
    return response;
  }

  public async getFlowerDistributionGraph(query = '') {
    const response = await getAPI(Action.FlowerDistributionGraph, query);
    return response;
  }

  public async getFlowerLinearGraph(query = '') {
    const response = await getAPI(Action.FlowerLinearGraph, query);
    return response;
  }

  public async getFruitDistributionGraph(query = '') {
    const response = await getAPI(Action.FruitDistributionGraph, query);
    return response;
  }

  public async getDryMatterDistributionGraph(query = '') {
    const response = await getAPI(Action.DryMatterDistributionGraph, query);
    return response;
  }

  public async getDryMatterPercentIncreaseGraph(query = '') {
    const response = await getAPI(Action.DryMatterPercentIncreaseGraph, query);
    return response;
  }

  public async getPackHouseGraph(query = '') {
    const response = await getAPI(Action.PackHouseGraph, query);
    return response;
  }

})();
